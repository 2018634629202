import React, { useEffect, useState } from "react"
import MapboxAutocomplete from "react-mapbox-autocomplete"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import MenuItem from "../components/MenuItem"
import MenuAddOnItem from "../components/MenuAddOnItem"

const MenuForm = () => {
  console.log(process.env.NODE_ENV)
  console.log(process.env.MAPBOX_KEY)
  // Group size and related state
  const [groupSize, setGroupSize] = useState(0)
  const [children, setChildren] = useState(0)
  const [infants, setInfants] = useState(0)
  const handleGroupSize = e => setGroupSize(e.target.value)
  const handleChildren = e => setChildren(e.target.value)
  const handleInfants = e => setInfants(e.target.value)

  // Setup fee calculation
  const [setupFee, setSetupFee] = useState(0)
  useEffect(() => {
    setSetupFee((parseInt(groupSize) < 100 ? (100 - parseInt(groupSize)) * 9 : 0).toFixed(2))
  }, [groupSize])

  // Meat items state and handlers
  const [porkSteak, setPorkSteak] = useState(0)
  const [porkSteakQty, setPorkSteakQty] = useState(0)
  const [extraPork, setExtraPork] = useState(0)
  const [extraPorkQty, setExtraPorkQty] = useState(0)
  const [porkCombined, setPorkCombined] = useState(0)
  const handlePorkSteak = e => {
    if (e?.target?.value) {
      setPorkSteak((parseFloat(e.target.value) * 16.45).toFixed(2))
      setPorkSteakQty(e.target.value)
    }
  }
  const handleExtraPork = e => {
    if (!e?.target?.value) {
      setExtraPorkQty(0)
      setExtraPork(0)
      return
    }
    setExtraPorkQty(e.target.value)
    setExtraPork((parseFloat(e.target.value) * 6).toFixed(2))
  }
  useEffect(() => {
    setPorkCombined((parseFloat(porkSteak) + parseFloat(extraPork)).toFixed(2))
  }, [extraPork, porkSteak])

  const [chicken, setChicken] = useState(0)
  const [chickenQty, setChickenQty] = useState(0)
  const [extraChicken, setExtraChicken] = useState(0)
  const [extraChickenQty, setExtraChickenQty] = useState(0)
  const [chickenCombined, setChickenCombined] = useState(0)
  const handleChicken = e => {
    if (e?.target?.value) {
      setChicken((parseFloat(e.target.value) * 18.75).toFixed(2))
      setChickenQty(e.target.value)
    }
  }
  const handleExtraChicken = e => {
    if (!e?.target?.value) {
      setExtraChickenQty(0)
      setExtraChicken(0)
      return
    }
    setExtraChickenQty(e.target.value)
    setExtraChicken((parseFloat(e.target.value) * 6).toFixed(2))
  }
  useEffect(() => {
    setChickenCombined((parseFloat(chicken) + parseFloat(extraChicken)).toFixed(2))
  }, [extraChicken, chicken])

  const [chickenPork, setChickenPork] = useState(0)
  const [chickenPorkQty, setChickenPorkQty] = useState(0)
  const [extraChickenPork, setExtraChickenPork] = useState(0)
  const [extraChickenPorkQty, setExtraChickenPorkQty] = useState(0)
  const [chickenPorkCombined, setChickenPorkCombined] = useState(0)
  const handleChickenPork = e => {
    if (e?.target?.value) {
      setChickenPork((parseFloat(e.target.value) * 18).toFixed(2))
      setChickenPorkQty(e.target.value)
    }
  }
  const handleExtraChickenPork = e => {
    if (!e?.target?.value) {
      setExtraChickenPorkQty(0)
      setExtraChickenPork(0)
      return
    }
    setExtraChickenPorkQty(e.target.value)
    setExtraChickenPork((parseFloat(e.target.value) * 6).toFixed(2))
  }
  useEffect(() => {
    setChickenPorkCombined((parseFloat(chickenPork) + parseFloat(extraChickenPork)).toFixed(2))
  }, [extraChickenPork, chickenPork])

  const [pigRoast, setPigRoast] = useState(0)
  const [pigRoastQty, setPigRoastQty] = useState(0)
  const handlePigRoast = e => {
    if (e?.target?.value) {
      e.target.value > 0
        ? e.target.value < 75
          ? setPigRoast((75 * 32.05).toFixed(2))
          : setPigRoast((e.target.value * 32.05).toFixed(2))
        : setPigRoast(0)
      setPigRoastQty(e.target.value)
      setDesert("Both")
      setDesertCharge(0)
    }
  }

  const [steak, setSteak] = useState(0)
  const [steakQty, setSteakQty] = useState(0)
  const [extraSteak, setExtraSteak] = useState(0)
  const [extraSteakQty, setExtraSteakQty] = useState(0)
  const [steakCombined, setSteakCombined] = useState(0)
  const handleSteak = e => {
    if (e?.target?.value) {
      setSteakQty(parseInt(e.target.value))
      setSteak((parseFloat(e.target.value) * 27.95).toFixed(2))
    }
  }
  const handleSteakMushrooms = e => {
    e.target.checked
      ? setSteak((parseFloat(steak) + steakQty * 3.25).toFixed(2))
      : setSteak((parseFloat(steak) - steakQty * 3.25).toFixed(2))
  }
  const handleSteakOnions = e => {
    e.target.checked
      ? setSteak((parseFloat(steak) + steakQty * 1.75).toFixed(2))
      : setSteak((parseFloat(steak) - steakQty * 1.75).toFixed(2))
  }
  const handleExtraSteak = e => {
    if (!e?.target?.value) {
      setExtraSteakQty(0)
      setExtraSteak(0)
      return
    }
    setExtraSteakQty(e.target.value)
    setExtraSteak((parseFloat(e.target.value) * 15).toFixed(2))
  }
  useEffect(() => {
    setSteakCombined((parseFloat(steak) + parseFloat(extraSteak)).toFixed(2))
  }, [extraSteak, steak])

  const [salmon, setSalmon] = useState(0)
  const [salmonQty, setSalmonQty] = useState(0)
  const [extraSalmon, setExtraSalmon] = useState(0)
  const [extraSalmonQty, setExtraSalmonQty] = useState(0)
  const [salmonCombined, setSalmonCombined] = useState(0)
  const handleSalmon = e => {
    if (e?.target?.value) {
      setSalmon((parseFloat(e.target.value) * 28.45).toFixed(2))
      setSalmonQty(e.target.value)
    }
  }
  const handleExtraSalmon = e => {
    if (!e?.target?.value) {
      setExtraSalmonQty(0)
      setExtraSalmon(0)
      return
    }
    setExtraSalmonQty(e.target.value)
    setExtraSalmon((parseFloat(e.target.value) * 15).toFixed(2))
  }
  useEffect(() => {
    setSalmonCombined((parseFloat(salmon) + parseFloat(extraSalmon)).toFixed(2))
  }, [extraSalmon, salmon])

  const [vegKabob, setVegKabob] = useState(0)
  const [vegKabobQty, setVegKabobQty] = useState(0)
  const [extraVegKabob, setExtraVegKabob] = useState(0)
  const [extraVegKabobQty, setExtraVegKabobQty] = useState(0)
  const [vegKabobCombined, setVegKabobCombined] = useState(0)
  const handleVegKabob = e => {
    if (e?.target?.value) {
      setVegKabob((parseFloat(e.target.value) * 16.45).toFixed(2))
      setVegKabobQty(e.target.value)
    }
  }
  const handleExtraVegKabob = e => {
    if (!e?.target?.value) {
      setExtraVegKabobQty(0)
      setExtraVegKabob(0)
      return
    }
    setExtraVegKabobQty(e.target.value)
    setExtraVegKabob((parseFloat(e.target.value) * 5).toFixed(2))
  }
  useEffect(() => {
    setVegKabobCombined((parseFloat(vegKabob) + parseFloat(extraVegKabob)).toFixed(2))
  }, [extraVegKabob, vegKabob])

  const [lobster, setLobster] = useState(0)
  const [lobsterQty, setLobsterQty] = useState(0)
  const handleLobster = e => {
    if (e?.target?.value) {
      setLobster((parseFloat(e.target.value) * 16.45).toFixed(2))
      setLobsterQty(e.target.value)
    }
  }

  const [roastBeef, setRoastBeef] = useState(0)
  const [roastBeefQty, setRoastBeefQty] = useState(0)
  const handleRoastBeef = e => {
    if (e?.target?.value) {
      setRoastBeef((parseFloat(e.target.value) * 25.70).toFixed(2))
      setRoastBeefQty(e.target.value)
      setDesert("Both")
      setDesertCharge(0)
    }
  }
  const handleRoastBeefMushrooms = e => {
    e.target.checked
      ? setRoastBeef((parseFloat(roastBeef) + roastBeefQty * 3.25).toFixed(2))
      : setRoastBeef((parseFloat(roastBeef) - roastBeefQty * 3.25).toFixed(2))
  }
  const handleRoastBeefOnions = e => {
    e.target.checked
      ? setRoastBeef((parseFloat(roastBeef) + roastBeefQty * 1.75).toFixed(2))
      : setRoastBeef((parseFloat(roastBeef) - roastBeefQty * 1.75).toFixed(2))
  }

  const [grabNgo, setGrabNgo] = useState(0)
  const [grabNGoQty, setGrabNgoQty] = useState(0)
  const [extraGrabNgo, setExtraGrabNgo] = useState(0)
  const [extraGrabNgoQty, setExtraGrabNGoQty] = useState(0)
  const [grabNgoCombined, setGrabNgoCombined] = useState(0)
  const handleGrabNgo = e => {
    if (e?.target?.value) {
      setGrabNgo((parseFloat(e.target.value) * 19.25).toFixed(2))
      setGrabNgoQty(e.target.value)
    }
  }
  const handleExtraGrabNgo = e => {
    if (!e?.target?.value) {
      setExtraGrabNGoQty(0)
      setExtraGrabNgo(0)
      return
    }
    setExtraGrabNGoQty(e.target.value)
    setExtraGrabNgo((parseFloat(e.target.value) * 6.50).toFixed(2))
  }
  useEffect(() => {
    setGrabNgoCombined((parseFloat(grabNgo) + parseFloat(extraGrabNgo)).toFixed(2))
  }, [extraGrabNgo, grabNgo])

  // Add-ons state and handlers
  const [desertCharge, setDesertCharge] = useState(0)
  const [desert, setDesert] = useState("")
  const handleDesert = e => {
    e.target.value === "Both"
      ? setDesertCharge((parseFloat(groupSize) * 0.75).toFixed(2))
      : setDesertCharge(0)
    setDesert(e.target.value)
  }

  const [popCharge, setPopCharge] = useState(0)
  const [pop, setPop] = useState("")
  const handlePop = e => {
    e.target.value === "Pop/Water/Juice"
      ? setPopCharge((parseFloat(groupSize) * 1.25).toFixed(2))
      : setPopCharge(0)
    setPop(e.target.value)
  }

  const [coffeeCharge, setCoffeeCharge] = useState(0)
  const [coffee, setCoffee] = useState("")
  const handleCoffee = e => {
    e.target.value === "Tea/Coffee"
      ? setCoffeeCharge((parseFloat(groupSize) * 0.5).toFixed(2))
      : setCoffeeCharge(0)
    setCoffee(e.target.value)
  }

  const [flatwareCharge, setFlatwareCharge] = useState(0)
  const [flatware, setFlatware] = useState("")
  const [clearing, setClearing] = useState("")
  const handleFlatware = e => {
    if (e.target.value === "Royal Chinette") {
      setFlatwareCharge(0)
      setFlatware("Royal Chinette")
    } else if (e.target.value === "Flatware rental") {
      setFlatware("Flatware rental")
      setFlatwareCharge((parseFloat(groupSize) * 1.75 * 1.15).toFixed(2))
      setClearing(
        "Clearing (required for flatware rental. Optionally available for all flatware options)"
      )
    } else if (e.target.value === "Upgraded Disposable Flatware") {
      setFlatware("Upgraded Disposable Flatware")
      setFlatwareCharge((parseFloat(groupSize) * 3).toFixed(2))
    }
  }

  // Address and travel fee state and handlers
  const [address, setAddress] = useState("")
  const [province, setProvince] = useState("")
  const [latLong, setLatLong] = useState([])
  const [travelFee, setTravelFee] = useState(0)
  const handleLocation = async e => setAddress(e)

  useEffect(() => {
    if (address === "") {
      setTravelFee(0)
      return
    }
    fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?access_token=pk.eyJ1IjoiYWRhbWNib3dtYW4iLCJhIjoiY2s3YWZndHJjMHY3bzNkbXlsY2oxdm0zbyJ9.NU4o56BdtvFhpVXVRCmn5g`
    )
      .then(res => res.json())
      .then(data => {
        let region = data.features[0]
          ? data.features[0].context.find(obj => obj.id.includes("region"))
          : null
        if (region) {
          setProvince(region.text)
          setLatLong(data.features[0].center)
        }
      })
      .catch(err => console.log(err))

    if (province === "Prince Edward Island") {
      setTravelFee(0)
    } else if (province === "New Brunswick") {
      fetch(
        `https://api.mapbox.com/directions/v5/mapbox/driving/${latLong[0]},${latLong[1]};-64.80011,46.097995?access_token=pk.eyJ1IjoiYWRhbWNib3dtYW4iLCJhIjoiY2s3YWZndHJjMHY3bzNkbXlsY2oxdm0zbyJ9.NU4o56BdtvFhpVXVRCmn5g`
      )
        .then(res => res.json())
        .then(data => {
          setTravelFee(
            Math.round((parseFloat(data.routes[0].duration) / 60 / 60) * 2 * 75).toFixed(2)
          )
        })
        .catch(err => console.log(err))
    } else if (province === "Nova Scotia") {
      fetch(
        `https://api.mapbox.com/directions/v5/mapbox/driving/${latLong[0]},${latLong[1]};-63.300006,45.366668?access_token=pk.eyJ1IjoiYWRhbWNib3dtYW4iLCJhIjoiY2s3YWZndHJjMHY3bzNkbXlsY2oxdm0zbyJ9.NU4o56BdtvFhpVXVRCmn5g`
      )
        .then(res => res.json())
        .then(data => {
          setTravelFee(
            Math.round((data.routes[0].duration / 60 / 60) * 2 * 75).toFixed(2)
          )
        })
        .catch(err => console.log(err))
    } else {
      window.alert("Travel estimate can't be generated for this address automatically. Please submit form and we will contact you with a travel estimate.")
      setTravelFee(0)
    }
  }, [address, province, latLong])

  // Total price calculation
  const [price, setPrice] = useState(0)
  useEffect(() => {
    setPrice(
      (
        (children * 9.95 +
          infants * 1 +
          Number(porkSteak) +
          Number(extraPork) +
          Number(steak) +
          Number(extraSteak) +
          Number(chicken) +
          Number(extraChicken) +
          Number(chickenPork) +
          Number(extraChickenPork) +
          Number(pigRoast) +
          Number(salmon) +
          Number(extraSalmon) +
          Number(vegKabob) +
          Number(extraVegKabob) +
          Number(lobster) +
          Number(roastBeef) +
          Number(grabNgo) +
          Number(extraGrabNgo) +
          Number(desertCharge) +
          Number(popCharge) +
          Number(coffeeCharge) +
          Number(flatwareCharge) +
          Number(travelFee) +
          (groupSize && groupSize > 0 ? Number(setupFee) : 0)) *
        1.15 *
        1.18
      ).toFixed(2)
    )
  }, [
    children,
    infants,
    porkSteak,
    extraPork,
    steak,
    extraSteak,
    chicken,
    extraChicken,
    chickenPork,
    extraChickenPork,
    salmon,
    extraSalmon,
    vegKabob,
    extraVegKabob,
    lobster,
    roastBeef,
    pigRoast,
    grabNgo,
    extraGrabNgo,
    popCharge,
    desertCharge,
    coffeeCharge,
    flatwareCharge,
    groupSize,
    travelFee,
    setupFee,
  ])

  const handleSubmit = e => {
    let shortProvince = ""
    if (province === "Prince Edward Island") {
      shortProvince = "PE"
    } else if (province === "New Brunswick") {
      shortProvince = "NB"
    } else if (province === "Nova Scotia") {
      shortProvince = "NS"
    } else {
      shortProvince = ""
    }

    e.preventDefault()
    const menu = getFormDetailsText()
    //navigate to google form link
    window.open(
      `https://docs.google.com/forms/d/e/1FAIpQLScPbMBQmOkvIY0S96sWENhdbeOjS0FZNWVMmkLoRD-YK4bPjA/viewform?usp=pp_url
      &entry.1196405965=${address}&entry.1275003710=${groupSize}&entry.1857878523=${children}&entry.845645177=${infants}&entry.1605025492=${menu}
      &entry.544174227=${shortProvince}&entry.2007045985=${desert}&entry.502266822=${coffee}&entry.502266822=${pop}&entry.671956053=${flatware}&entry.671956053=${clearing}`,
      "_blank"
    )
  }

  const getFormDetailsText = () => {
    let menu = ``
    steakQty > 0 && (menu += `Steak: ${steakQty} @$${steak}, \n`)
    extraSteakQty > 0 &&
      (menu += `Extra Steak: ${extraSteakQty} @$${extraSteak}, \n`)
    //check if mushrooms/onions are selected on steak
    document.getElementById("steak-mushrooms").checked &&
      (menu += `w/ mushrooms, \n`)
    document.getElementById("steak-onions").checked && (menu += `w/ onions, \n`)
    porkSteakQty > 0 && (menu += `Pork: ${porkSteakQty} @$${porkSteak}, \n`)
    extraPorkQty > 0 &&
      (menu += `Extra Pork: ${extraPorkQty} @$${extraPork}, \n`)
    chickenQty > 0 && (menu += `Chicken: ${chickenQty} @$${chicken}, \n`)
    extraChickenQty > 0 &&
      (menu += `Extra Chicken: ${extraChickenQty} @$${extraChicken}, \n`)
    salmonQty > 0 && (menu += `Salmon: ${salmonQty} @$${salmon}, \n`)
    extraSalmonQty > 0 &&
      (menu += `Extra Salmon: ${extraSalmonQty} @$${extraSalmon}, \n`)
    vegKabobQty > 0 &&
      (menu += `Tofu Veg Kabob: ${vegKabobQty} @$${vegKabob}, \n`)
    extraVegKabobQty > 0 &&
      (menu += `Extra Tofu Veg Kabob: ${extraVegKabobQty} @$${extraVegKabob}, \n`)
    lobsterQty > 0 && (menu += `Lobster: ${lobsterQty} @$${lobster}, \n`)
    chickenPorkQty > 0 &&
      (menu += `Chicken/Pork: ${chickenPorkQty} @$${chickenPork}, \n`)
    extraChickenPorkQty > 0 &&
      (menu += `Extra Chicken/Pork: ${extraChickenPorkQty} @$${extraChickenPork}, \n`)
    roastBeefQty > 0 &&
      (menu += `Roast Beef: ${roastBeefQty} @$${roastBeef}, \n`)
    document.getElementById("roastbeef-mushrooms").checked &&
      (menu += `w/ mushrooms, \n`)
    document.getElementById("roastbeef-onions").checked &&
      (menu += `w/ onions, \n`)
    pigRoastQty > 0 && (menu += `Pig Roast: ${pigRoastQty} @$${pigRoast}, \n`)
    grabNGoQty > 0 && (menu += `Grab N Go: ${grabNGoQty} @$${grabNgo}, \n`)
    extraGrabNgoQty > 0 &&
      (menu += `Extra Grab N Go: ${extraGrabNgoQty} @$${extraGrabNgo}, \n`)

    menu += `Desert: ${desert} @$${desertCharge},
    Coffee: $${coffeeCharge},
    Pop/Water/Juice: $${popCharge}
    Flatware: ${flatware} @$${flatwareCharge}
    Setup Fee: $${setupFee},  
    Travel Fee: $${travelFee},
    Total: $${price}
      `
    return menu
  }

  return (
    <Layout>
      <Seo title="Sizzler BBQ Catering Menu Selection Form" />

      <div className="flex flex-col items-center p-6">
        <h1 className="text-2xl font-bold text-primary">Menu Selection Form</h1>
      </div>

      <div className=" text-center pt-2">
        <a href="tel:1-800-805-2227">
          <FontAwesomeIcon icon={faPhone} />
          {"  "}
          Call for free Consultation
        </a>
      </div>

      <div className="flex flex-col items-left">
        {/* group size */}
        <p className="p-2 pt-4 text-xs">
          Select the total number of guests in your group.
        </p>
        <div className="flex flex-col w-full md:flex-row items-center justify-items-center border">
          <label htmlFor="group-size" className="p-2  label">
            Group Size - Adults
          </label>
          <input
            type="number"
            inputMode="numeric"
            min={0}
            id="group-size"
            className="p-2 input input-bordered input-primary"
            defaultValue={0}
            onClick={(e) => e.target.value = ""}
            onChange={handleGroupSize}
          />

          <label htmlFor="children" className="p-2  label">
            Children (7-12)
          </label>
          <input
            type="number"
            inputMode="numeric"
            min={0}
            id="children"
            className="p-2 input input-bordered input-primary"
            defaultValue={0}
            onClick={(e) => e.target.value = ""}
            onChange={handleChildren}
          />
          <label htmlFor="infants" className="p-2 label">
            Infants (0-6)
          </label>
          <input
            type="number"
            inputMode="numeric"
            min={0}
            id="infants"
            className="p-2 input input-bordered input-primary"
            defaultValue={0}
            onClick={(e) => e.target.value = ""}
            onChange={handleInfants}
          />
        </div>

        <p className="p-2 text-xs">
          Select the number of servings for each menu choice below. Buffet is
          included with all menu options. <Link to="/menu">See menu</Link> for
          details
        </p>
        <p className="p-2 text-xs">
          *Extra meat portions may be added to some menu choices using the Extra
          Meat Portions checkbox. This is a great option to ensure that everyone
          gets their fill of their favourite menu item. Charge varies by menu
          item at a value added price.
        </p>

        {/* chicken/pork */}
        <MenuItem
          key="chickenPork"
          itemId="chickenPork"
          label="Chicken(60%) / Pork(40%) @ $18"
          description="Most popular menu choice."
          details="A combination of our 2 most popular items, Chicken Breast & Pork Steak!"
          handleChange={handleChickenPork}
          price={chickenPork}
          handleExtra={handleExtraChickenPork}
          extraMeatPortions={extraChickenPorkQty}
          extraMeatPrice={extraChickenPork}
          combinedTotal={chickenPorkCombined}
        />

        {/* pork steak */}
        <MenuItem
          key="porkSteak"
          itemId="porkSteak"
          label="Boneless Pork Steak @ $16.45"
          description=""
          details=""
          handleChange={handlePorkSteak}
          price={porkSteak}
          handleExtra={handleExtraPork}
          extraMeatPortions={extraPorkQty}
          extraMeatPrice={extraPork}
          combinedTotal={porkCombined}
        />

        {/* chicken */}
        <MenuItem
          key="chicken"
          itemId="chicken"
          label="Chicken @ $18.75"
          description=""
          details="6oz Boneless Chicken Breast"
          handleChange={handleChicken}
          price={chicken}
          handleExtra={handleExtraChicken}
          extraMeatPortions={extraChickenQty}
          extraMeatPrice={extraChicken}
          combinedTotal={chickenCombined}
        />

        {/* steak */}
        <div className="flex flex-col md:flex-row items-center border p-2">
          <div className="w-full md:w-1/2">
            <label htmlFor="steak" className="p-2 label">
              Steak@ $27.95
            </label>
            <p className="p-2 text-xs">10oz Strip Loin Steak </p>
            <div className="flex px-2">
              <input
                type="checkbox"
                id="steak-mushrooms"
                onChange={handleSteakMushrooms}
              />
              <label htmlFor="steak-mushrooms" className="p-2 label text-xs">
                Add mushrooms @ $3.25
              </label>
            </div>
            <div className="flex px-2">
              <input
                type="checkbox"
                id="steak-onions"
                onChange={handleSteakOnions}
              />
              <label htmlFor="steak-onions" className="p-2 label text-xs">
                Add onions @ $1.75
              </label>
            </div>
          </div>
          <input
            type="number"
            id="steak"
            className="p-2 input input-bordered input-primary w-1/6"
            onChange={handleSteak}
            defaultValue={0}
          />
          <p className="p-2">${steak}</p>
            <div className="flex items-center">
            <input
              type="checkbox"
              className="checkbox"
              id="extraSteak"
              onChange={() => {
                document.getElementById("extraSteakQty").classList.toggle("hidden")
                document.getElementById("extraSteakQty").classList.toggle("flex")
                handleExtraSteak(0)
              }}
            />
            <label
              htmlFor="extraMeat"
              className="px-2 pt-2 text-xs font-semibold"
            >
              Extra Meat Portions*
            </label>
              <div className="hidden" id="extraSteakQty">
                <input
                  type="number"
                  inputMode="numeric"
                  min="0"
                  className="px-2 input input-bordered input-primary input-medium w-1/2"
                  value={extraSteakQty}
                  onChange={handleExtraSteak}
                />
                <p className="p-2">${extraSteak}</p>
                <p className="p-2">${steakCombined}</p>
              </div>
          </div>
        </div>

        {/* Salmon */}
        <MenuItem
          key="salmon"
          itemId="salmon"
          label="Salmon @ $28.45"
          description=""
          details=""
          handleChange={handleSalmon}
          price={salmon}
          handleExtra={handleExtraSalmon}
          extraMeatPortions={extraSalmonQty}
          extraMeatPrice={extraSalmon}
          combinedTotal={salmonCombined}
        />

        {/* Tofu Veggie Kabob  */}
        <MenuItem
          key="vegKabob"
          itemId="vegKabob"
          label="Tofu Veggie Kabob @ $16.45"
          description=""
          details="Available without tofu on request."
          handleChange={handleVegKabob}
          price={vegKabob}
          handleExtra={handleExtraVegKabob}
          extraMeatPortions={extraVegKabobQty}
          extraMeatPrice={extraVegKabob}
          combinedTotal={vegKabobCombined}
        />

        {/* Lobster  */}
        <MenuItem
          key="lobster"
          itemId="lobster"
          label="Lobster @ $16.45"
          description=""
          details="+ Lobster cost (you set the size and quantity)"
          handleChange={handleLobster}
          price={lobster}
          // <p className="p-2">
          // ${lobster} {lobster > 0 ? `+ Lobster cost` : ""}
          // </p>
        />

        {/* pig roast */}
        <MenuItem
          key="pigRoast"
          itemId="pig-roast"
          label="Pork Buffet (whole succulent roast pig) @ $32.05"
          description=""
          details="Price based on min 75 person group. Includes both desserts."
          handleChange={handlePigRoast}
          price={pigRoast}
        />

        {/* Unlimited Hip of Beef  */}
        <div className="flex flex-col md:flex-row items-center border p-2">
          <div className="w-full md:w-1/2">
            <label htmlFor="roastBeef" className="p-2 label">
              Unlimited Hip of Beef @ $25.70
            </label>
            <br />
            <p className="p-2 text-xs">Includes both desserts</p>
            <div className="flex px-2">
              <input
                type="checkbox"
                id="roastbeef-mushrooms"
                onChange={handleRoastBeefMushrooms}
              />
              <label
                htmlFor="roastbeef-mushrooms"
                className="p-2 label text-xs"
              >
                Add mushrooms @ $3.25
              </label>
            </div>
            <div className="flex px-2">
              <input
                type="checkbox"
                id="roastbeef-onions"
                onChange={handleRoastBeefOnions}
              />
              <label
                htmlFor="roastbeef-mushrooms"
                className="p-2 label text-xs"
              >
                Add onions @ $1.75
              </label>
            </div>
          </div>
          <input
            type="number"
            id="roastBeef"
            className="p-2 input input-bordered input-primary w-1/6 "
            onChange={handleRoastBeef}
            defaultValue={0}
          />
          <p className="p-2">${roastBeef}</p>
        </div>

        {/* grabNgo on a bun mix */}
        <MenuItem
          key="grabNgo"
          itemId="grabNgo"
          label="Grab n' Go - 'On a Bun' mix @ $19.25"
          description="Chicken, Sausage, Burger, Pulled Pork."
          details="Choose any of the above grab n' go options, or combine them all. Includes full salad buffet. 
          Please indicate menu choices and quantities on next page."
          handleChange={handleGrabNgo}
          price={grabNgo}
          handleExtra={handleExtraGrabNgo}
          extraMeatPortions={extraGrabNgoQty}
          extraMeatPrice={extraGrabNgo}
          combinedTotal={grabNgoCombined}
        />

        {/* pop/water/juice */}
        <MenuAddOnItem
          key="pop"
          itemId="pop"
          label="Pop/Water/Juice"
          details="A selection of cold beverages."
          selectOptions={[
            {
              value: "",
              description: "Select",
            },
            {
              value: "Pop/Water/Juice",
              description: "Pop/Water/Juice (+$1.25/person)",
            },
            {
              value: "No",
              description: "No",
            },
          ]}
          handleChange={handlePop}
          price={popCharge}
        />

        {/* desert */}
        <MenuAddOnItem
          key="desert"
          itemId="desert"
          label="Deserts"
          details="Buffet includes choice of 1 desert at no additional charge. Both
          deserts available for $0.75/person."
          selectOptions={[
            {
              value: "",
              description: "Select",
            },
            {
              value: "Carrot Cake",
              description: "Carrot Cake",
            },
            {
              value: "Apple Crisp",
              description: "Apple Crisp",
            },
            {
              value: "Both",
              description: "Both (+$0.75/person)",
            },
          ]}
          handleChange={handleDesert}
          price={desertCharge}
        />

        {/* coffee */}
        <MenuAddOnItem
          key="Coffee"
          itemId="Coffee"
          label="Coffee/Tea"
          details="power required - electrical outlets must be 15 amp for each
          coffee/tea unit"
          selectOptions={[
            {
              value: "",
              description: "Select",
            },
            {
              value: "Tea/Coffee",
              description: "Coffee/Tea (+$0.50/person)",
            },
            {
              value: "No",
              description: "No",
            },
          ]}
          handleChange={handleCoffee}
          price={coffeeCharge}
        />

        {/* flatware */}
        <MenuAddOnItem
          key="flatware"
          itemId="flatware"
          label="Flatware"
          details="Disposable flatware is included in the price of the buffet. If
          you would like to upgrade to a more elegant flatware, or rent
          flatware, please select from the options below."
          selectOptions={[
            {
              value: "",
              description: "Select",
            },
            {
              value: "Royal Chinette",
              description: "RoyalChinette - disposable",
            },
            {
              value: "Flatware rental",
              description:
                "Flatware Rental (+ $1.75/person & 15% clearing charge)",
            },
            {
              value: "Upgraded Disposable Flatware",
              description: "Disposable Flatware Upgrade (+ $3/person)",
            },
          ]}
          handleChange={handleFlatware}
          price={flatwareCharge}
        />

        {/* setup fee */}
        {groupSize > 0 && groupSize < 100 ? (
          <>
            {/* <div className="flex items-center justify-between p-2 "> */}
            <div className="py-2 flex flex-col md:flex-row md:items-center border justify-between">
              <p>Set Up Charge:</p> <p>${setupFee}</p>
            </div>
            <p className="text-xs p-2">
              Applies to groups under 100. $9/person less than 100. eg: 50
              people = $450
            </p>
          </>
        ) : null}

        {/* location */}
        <div className="items-center justify-between border">
          <div className="flex flex-col md:flex-row items-center border justify-between">
            <div className="md:w-1/3">
              <label htmlFor="location" className="label p-2">
                Event Location (address)
              </label>
            </div>
            <div className=" w-full md:w-2/3 py-2">
              <MapboxAutocomplete
                publicKey={process.env.MAPBOX_KEY}
                inputClass="form-control input input-bordered input-primary w-full mt-3"
                onSuggestionSelect={handleLocation}
                country="ca"
                placeholder="Enter Event Location Address"
                resetSearch={false}
              />
            </div>
            <p className="p-2">${travelFee}</p>
          </div>
          <p className="text-xs">
            Travel cost may apply. <br />
            Travel estimates calculated for NS, PEI & NB only. <br />
            Travel cost from Moncton, NB or Turo, N.S. = hours x 2 x $75.00
          </p>
        </div>

        <div className="mt-8 mb-4 flex items-center border justify-between">
          <label htmlFor="totalCost" className="p-2 text-xl">
            Total Cost
          </label>
          <p className="p-2 text-xl">${price}</p>
        </div>
        {/* submit button */}
        <div className="flex justify-center">
          <p className="text-xs">
            Total Cost price displayed represents an initial estimate only based
            on the provided details. Final price will be calculated based on any
            additional requirements, and will be confirmed prior to request for
            payment after booking is finalized. Any additional menu requests can
            be noted in the Service Request Form. <br />
            See <Link to="/menu">Menu page</Link> for additional menu options
            that may be available. <br />
            Price includes 18% gratuity and 15% HST.
          </p>
        </div>
        <button
          type="button"
          className="btn btn-md btn-primary"
          onClick={handleSubmit}
        >
          Continue to Service Request Form
        </button>
      </div>
    </Layout>
  )
}

export default MenuForm
